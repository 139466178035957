/* eslint-disable max-lines */
import React, { useState } from 'react'

import {Helmet} from 'react-helmet';
import { useTranslation } from 'react-i18next'
import './docs.scss';

import Banner from 'components/banner';
import {Tooltip} from 'components/common';
import apiProducts from '../../../../assets/images/aisp/api-products.png'
import PtapiProducts from '../../../../assets/images/aisp/api-products-pt.png'
import testApp from '../../../../assets/images/aisp/test-app.png'
import PttestApp from '../../../../assets/images/aisp/test-app-pt.png'
import swaggerAisp from '../../../../assets/images/aisp/swagger-aisp.png'
import PtswaggerAisp from '../../../../assets/images/aisp/swagger-aisp-pt.png'
import tppAuth from '../../../../assets/images/aisp/tpp-authentication.png'
import tryitOut from '../../../../assets/images/aisp/try-it-out.png'
import execute from '../../../../assets/images/aisp/execute.png'
import availableAspsp from '../../../../assets/images/aisp/available-aspsp.png'
import integratedAspspReq from '../../../../assets/images/aisp/get-integrated-aspsp.png'
import integratedAspsp from '../../../../assets/images/aisp/integrated-aspsp.png'
import connectBank from '../../../../assets/images/aisp/connect-bank.png'
import modelBody from '../../../../assets/images/aisp/model-body.png'
import authUri from '../../../../assets/images/aisp/auth-uri.png'
import bankAuth from '../../../../assets/images/aisp/bank-auth.png'
import accountsConfirm from '../../../../assets/images/aisp/accounts-confirm.png'
import getBankDetailReq from '../../../../assets/images/aisp/getBank-detail-req.png'
import getBankDetailResp from '../../../../assets/images/aisp/getBank-detail-resp.png'
import revokeConsentReq from '../../../../assets/images/aisp/revoke-consent-req.png'
import revokeConsentResp from '../../../../assets/images/aisp/revoke-consent-resp.png'
import getConsentReq from '../../../../assets/images/aisp/get-consents-req.png'
import getConsentResp from '../../../../assets/images/aisp/get-consents-resp.png'
import getAccountsReq from '../../../../assets/images/aisp/get-accounts-req.png'
import getAccountsResp from '../../../../assets/images/aisp/get-accounts-resp.png'
import getTransactionsReq from '../../../../assets/images/aisp/get-transactions-req.png'
import getTransactionsResp from '../../../../assets/images/aisp/get-transactions-resp.png'
import getIntegatedAccountsReq from '../../../../assets/images/aisp/get-integ-accounts-req.png'
import getIntegatedAccountsResp from '../../../../assets/images/aisp/get-integ-accounts-resp.png'
import getAccountDetailReq from '../../../../assets/images/aisp/get-accountDetail-req.png'
import getAccountDetailRes from '../../../../assets/images/aisp/get-accountDetail-res.png'
import getBalanceDetailReq from '../../../../assets/images/aisp/get-balanceDetail-req.png'
import getBalanceDetailRes from '../../../../assets/images/aisp/get-balanceDetail-res.png'
import getTransactionDetailReq from '../../../../assets/images/aisp/get-transactionDetail-req.png'
import getTransactionDetailRes from '../../../../assets/images/aisp/get-transactionDetail-res.png'
import getpersonalIdReq from '../../../../assets/images/aisp/personal-id-req.png'
import getpersonalIdRes from '../../../../assets/images/aisp/personal-id-res.png'
import getpersonalQualificationReq from '../../../../assets/images/aisp/personal-qualification-req.png'
import getpersonalQualificationRes from '../../../../assets/images/aisp/personal-qualification-res.png'
import getpersonalFinancialReq from '../../../../assets/images/aisp/personal-financial-req.png'
import getpersonalFinancialRes from '../../../../assets/images/aisp/personal-financial-res.png'
import getbusinessIdReq from '../../../../assets/images/aisp/business-id-req.png'
import getbusinessIdRes from '../../../../assets/images/aisp/business-id-res.png'
import getbusinessQualificationReq from '../../../../assets/images/aisp/business-qualification-req.png'
import getbusinessQualificationRes from '../../../../assets/images/aisp/business-qualification-res.png'
import getbusinessFinancialReq from '../../../../assets/images/aisp/business-financial-req.png'
import getbusinessFinancialRes from '../../../../assets/images/aisp/business-financial-res.png'
import getresourcesReq from '../../../../assets/images/aisp/resources-req.png'
import getresourcesRes from '../../../../assets/images/aisp/resources-res.png'
import getCreditAccountsReq from '../../../../assets/images/aisp/credit-card-accounts-req.png'
import getCreditAccountsResp from '../../../../assets/images/aisp/credit-card-accounts-res.png'
import getCreditDetailReq from '../../../../assets/images/aisp/credit-card-detail-req.png'
import getCreditDetailResp from '../../../../assets/images/aisp/credit-card-detail-res.png'
import getCreditLimitReq from '../../../../assets/images/aisp/credit-card-limit-req.png'
import getCreditLimitResp from '../../../../assets/images/aisp/credit-card-limit-res.png'
import getCreditTransacationReq from '../../../../assets/images/aisp/credit-card-trans-req.png'
import getCreditTransacationResp from '../../../../assets/images/aisp/credit-card-trans-res.png'
import getCurrentTransacationReq from '../../../../assets/images/aisp/current-trans-req.png'
import getCurrentTransacationResp from '../../../../assets/images/aisp/current-trans-res.png'
import getCreditBillsReq from '../../../../assets/images/aisp/credit-card-bills-req.png'
import getCreditBillsResp from '../../../../assets/images/aisp/credit-card-bills-res.png'
import getCreditBillsTransReq from '../../../../assets/images/aisp/credit-card-bills-trans-req.png'
import getCreditBillsTransResp from '../../../../assets/images/aisp/credit-card-bills-trans-res.png'

const AispFlow = () => {
  const { t, i18n } = useTranslation();
  const [copyPayload, setCopyPayload] = useState(false);
  const content ={
    title: t('pages.documentation.aispFlow'),
    description: t('staticPage.aisp.description'),
    className: 'userManagementImage'
  }

  const handleCopyPayloadClick = (children) => {
      navigator.clipboard.writeText(children.textContent);

      setCopyPayload(true);
      setTimeout(() => setCopyPayload(false), 1000)
  }

  return (
        <>
          <Helmet title={content.title} />
          {/* <Banner {...content}/> */}
          <div id="main">
            <section class="section section--gradient">
              <div class="inner content">
                <div class="page-content">
                    <h3>{`${t('pages.documentation.aispFlow')}`}</h3>
                    <p>{`${t('staticPage.aisp.product')}`} <code>AIS</code>.</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={i18n.language == 'en' ? apiProducts: PtapiProducts} loading="lazy" alt="onboarding-registration"/>
                    </div>
                    <p>{`${t('staticPage.aisp.followSteps')}`}:</p>
                    <h3>1. {`${t('staticPage.aisp.selectApp')}`}</h3>
                    <p>{`${t('staticPage.aisp.onClick')}`}<code>Test</code>{`${t('staticPage.aisp.test')}`} </p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={i18n.language == 'en' ? testApp: PttestApp} loading="lazy" alt="onboarding-registration"/>
                    </div>
                    <h3>2. {`${t('staticPage.aisp.devAuth')}`}</h3>
                    <p>{`${t('staticPage.aisp.authorize')}`}</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={i18n.language == 'en' ? swaggerAisp: PtswaggerAisp} loading="lazy" alt="swagger-pisp"/>
                    </div>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={tppAuth} loading="lazy" alt="tpp-auth"/>
                    </div>
                    <h3>3. OBAF AISP API</h3>
                    <p>{`${t('staticPage.aisp.totalApi')}`}</p>
                    <h3>3.1 Available ASPSPs API</h3>
                    <h3>3.1.1 {`${t('staticPage.aisp.getAspsp')}`}</h3>
                    <p>{`${t('staticPage.aisp.authHeader')}`} {`${t('staticPage.softwareStatement.onClick')}`}<code>{`${t('staticPage.aisp.tryItout')}`}</code> {`${t('staticPage.softwareStatement.button')}`}.</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={tryitOut} loading="lazy"/>
                    </div>
                    <p>{`${t('staticPage.aisp.execute')}`}</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={execute} loading="lazy"/>
                    </div>
                    <p>{`${t('staticPage.aisp.available')}`} <code>{`${t('staticPage.aisp.bank')}`}</code> {`${t('staticPage.aisp.fintech')}`}. {`${t('staticPage.aisp.id')}`} <code>aspspId</code>.</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={availableAspsp} loading="lazy"/>
                    </div>
                    <h3>3.2 Accounts API</h3>
                    <h3>3.2.1 {`${t('staticPage.aisp.getIntAspsp')}`}</h3>
                    <p>{`${t('staticPage.aisp.tryOut')}`} <code>/api​/providers​/integrated</code> API. {`${t('staticPage.aisp.listAspsp')}`} (<code>x-user-id</code>).</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={integratedAspspReq} loading="lazy"/>
                    </div>
                    <p>{`${t('staticPage.aisp.success')}`} <code>{`${t('staticPage.aisp.bank')}`}</code>{`${t('staticPage.aisp.endUser')}`} </p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={integratedAspsp} loading="lazy"/>
                    </div>
                    <h3>3.2.2 {`${t('staticPage.aisp.connectAspsp')}`}</h3>
                    <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code> API. {`${t('staticPage.aisp.integrateAspsp')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.endUser')}`} (<code>x-user-id</code>).</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={connectBank} loading="lazy"/>
                    </div>
                    <p>{`${t('staticPage.aisp.payload')}`}:</p>
                    <pre style={{display: 'flex'}}>
                      <code id="payload">
                      {`
{
      "expirationDateTime": "2023-05-21T08:30:00Z",
      "identification": {
            "cpf": "76109277673",
            "cnpj": "21018182000106"
      },
      "permissions": ["RESOURCES_READ", "ACCOUNTS_READ", "ACCOUNTS_BALANCES_READ", "ACCOUNTS_TRANSACTIONS_READ"],
      "fintechRedirectUri": "https://www.fintech.com/account/done"
}
`}
                      </code>
                      <code>
                      {
                        copyPayload ? <span class="icon alt fa-regular fa-check"></span> : (
                        <Tooltip title="Copy">
                          <span class="icon alt fa-solid fa-copy" onClick={() => handleCopyPayloadClick(document.getElementById('payload'))}></span>
                        </Tooltip>
                        )
                      }
                    </code>
                  </pre>
                  <p>{`${t('staticPage.aisp.modalDesc')}`}</p>
                  <p>{`${t('staticPage.aisp.note')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={modelBody} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successReq')}`} <code>authUri</code> {`${t('staticPage.aisp.field')}`} <code>ASPSP/bank Authentication URI</code>. {`${t('staticPage.aisp.authPage')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={authUri} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.bankLoginPage')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={bankAuth} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.review')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={accountsConfirm} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.redirect')}`}</p>
                  <h3>3.2.3 {`${t('staticPage.aisp.getIntAspspDetail')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET /api/providers/integrated/{aspspId}`}</code> API. {`${t('staticPage.aisp.aspspDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.intEndUser')}`} (<code>x-user-id</code>).</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getBankDetailReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successInt')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getBankDetailResp} loading="lazy"/>
                  </div>
                  <h3>3.2.4 {`${t('staticPage.aisp.revoke')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`DELETE ​/api​/providers​/integrated​/{aspspId}`}</code>API {`${t('staticPage.aisp.revokeAccess')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.particularEndUser')}`} (<code>x-user-id</code>).</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={revokeConsentReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successRes')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={revokeConsentResp} loading="lazy"/>
                  </div>
                  <h3>3.2.5 {`${t('staticPage.aisp.listAspspConsents')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}​/consents`}</code>API. {`${t('staticPage.aisp.listConsents')}`} (<code>x-user-id</code>) for a specific ASPSP/bank (<code>aspspId</code>).</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getConsentReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successConsent')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getConsentResp} loading="lazy"/>
                  </div>
                  <h3>3.2.6 {`${t('staticPage.aisp.listAllAccs')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/accounts`}</code> API.{`${t('staticPage.aisp.listIntAccs')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>ACCOUNTS_READ</code> {`${t('staticPage.aisp.accData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getAccountsReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successAcc')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getAccountsResp} loading="lazy"/>
                  </div>
                  <h3>3.2.7 {`${t('staticPage.aisp.listAllTrans')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/transactions`}</code>API. {`${t('staticPage.aisp.listTrans')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>ACCOUNTS_TRANSACTIONS_READ</code> {`${t('staticPage.aisp.transData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getTransactionsReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successTrans')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getTransactionsResp} loading="lazy"/>
                  </div>
                  <h3>3.2.8 {`${t('staticPage.aisp.listAspspAccs')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET /api​/providers​/integrated​/{aspspId}​/accounts`}</code>API {`${t('staticPage.aisp.listIntAspspAccs')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>ACCOUNTS_READ</code> {`${t('staticPage.aisp.accsData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getIntegatedAccountsReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successAccs')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getIntegatedAccountsResp} loading="lazy"/>
                  </div>
                  <h3>3.2.9 {`${t('staticPage.aisp.accDetail')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}​/accounts​/{accountId}`}</code>API {`${t('staticPage.aisp.listAccDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>ACCOUNTS_READ</code> {`${t('staticPage.aisp.listAllAccDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getAccountDetailReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successAccDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getAccountDetailRes} loading="lazy"/>
                  </div>
                  <h3>3.2.10 {`${t('staticPage.aisp.balanceDetail')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}​/accounts​/{accountId}/balances`}</code>API {`${t('staticPage.aisp.listBalDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>ACCOUNTS_BALANCES_READ</code> {`${t('staticPage.aisp.listAllBalDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getBalanceDetailReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successBalDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getBalanceDetailRes} loading="lazy"/>
                  </div>
                  <h3>3.2.11 {`${t('staticPage.aisp.transDetail')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}​/accounts​/{accountId}/transactions`}</code>API {`${t('staticPage.aisp.listTransDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`}  (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>ACCOUNTS_TRANSACTIONS_READ</code> {`${t('staticPage.aisp.listAllTransDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getTransactionDetailReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successTransDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getTransactionDetailRes} loading="lazy"/>
                  </div>
                  <h3>3.3 Customers API</h3>
                  <h3>3.3.1 {`${t('staticPage.aisp.personalIdenData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}/personal/identifications`}</code>API {`${t('staticPage.aisp.listpersonalIdenDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ</code> {`${t('staticPage.aisp.listAllpersonalIdenDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getpersonalIdReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successpersonalIdenDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getpersonalIdRes} loading="lazy"/>
                  </div>
                  <h3>3.3.2 {`${t('staticPage.aisp.personalQualificationData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}/personal/qualifications`}</code>API {`${t('staticPage.aisp.listpersonalQualificationDetail')}`} (<code>aspspId</code>){`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CUSTOMERS_PERSONAL_ADITTIONALINFO_READ</code> {`${t('staticPage.aisp.listAllpersonalQualificationDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getpersonalQualificationReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successpersonalQualificationDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getpersonalQualificationRes} loading="lazy"/>
                  </div>
                  <h3>3.3.3 {`${t('staticPage.aisp.personalFinancialData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}/personal/financial-relations`}</code>API {`${t('staticPage.aisp.listpersonalFinancialDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CUSTOMERS_PERSONAL_ADITTIONALINFO_READ</code> {`${t('staticPage.aisp.listAllpersonalFinancialDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getpersonalFinancialReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successpersonalFinancialDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getpersonalFinancialRes} loading="lazy"/>
                  </div>
                  <h3>3.3.4 {`${t('staticPage.aisp.businessIdenData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}/business/identifications`}</code>API {`${t('staticPage.aisp.listbusinessIdenDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CUSTOMERS_BUSINESS_IDENTIFICATIONS_READ</code> {`${t('staticPage.aisp.listAllbusinessIdenDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getbusinessIdReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successbusinessIdenDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getbusinessIdRes} loading="lazy"/>
                  </div>
                  <h3>3.3.5 {`${t('staticPage.aisp.businessQualificationData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}/business/qualifications`}</code>API {`${t('staticPage.aisp.listbusinessQualificationDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CUSTOMERS_BUSINESS_ADITTIONALINFO_READ</code> {`${t('staticPage.aisp.listAllbusinessQualificationDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getbusinessQualificationReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successbusinessQualificationDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getbusinessQualificationRes} loading="lazy"/>
                  </div>
                  <h3>3.3.6 {`${t('staticPage.aisp.businessFinancialData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}/business/financial-relations`}</code>API {`${t('staticPage.aisp.listbusinessFinancialDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CUSTOMERS_BUSINESS_ADITTIONALINFO_READ</code> {`${t('staticPage.aisp.listAllbusinessFinancialDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getbusinessFinancialReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successbusinessFinancialDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getbusinessFinancialRes} loading="lazy"/>
                  </div>
                  <h3>3.4 Resources API</h3>
                  <h3>3.4.1 {`${t('staticPage.aisp.resourcesDetail')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}/resources`}</code>API {`${t('staticPage.aisp.listresourceDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>RESOURCES_READ</code> {`${t('staticPage.aisp.listAllresourceDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getresourcesReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successresourceDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getresourcesRes} loading="lazy"/>
                  </div>
                  <h3>3.5 Credit Card API</h3>
                  <h3>3.5.1 {`${t('staticPage.aisp.creditAccDetail')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET ​/api​/providers​/integrated​/{aspspId}​/credit-cards-accounts​/accounts`}</code>API {`${t('staticPage.aisp.listcreditAccDetail')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CREDIT_CARDS_ACCOUNTS_READ</code> {`${t('staticPage.aisp.listAllcreditAccDetail')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditAccountsReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successcreditAccDetail')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditAccountsResp} loading="lazy"/>
                  </div>
                  <h3>3.5.2 {`${t('staticPage.aisp.creditAccDetailData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET /api​/providers​/integrated​/{aspspId}​/credit-cards-accounts​/accounts/{creditCardAccountId}`}</code>API {`${t('staticPage.aisp.listcreditAccDetailData')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CREDIT_CARDS_ACCOUNTS_READ</code> {`${t('staticPage.aisp.listAllcreditAccDetailData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditDetailReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successcreditAccDetailData')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditDetailResp} loading="lazy"/>
                  </div>
                  <h3>3.5.3 {`${t('staticPage.aisp.creditAccLimitData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET /api​/providers​/integrated​/{aspspId}​/credit-cards-accounts​/accounts/{creditCardAccountId}/limits`}</code>API {`${t('staticPage.aisp.listcreditAccLimitData')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>:{`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CREDIT_CARDS_ACCOUNTS_LIMITS_READ</code> {`${t('staticPage.aisp.listAllcreditAccLimitData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditLimitReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successcreditAccLimitData')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditLimitResp} loading="lazy"/>
                  </div>
                  <h3>3.5.4 {`${t('staticPage.aisp.creditAccTransData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET /api​/providers​/integrated​/{aspspId}​/credit-cards-accounts​/accounts/{creditCardAccountId}/transactions`}</code>API {`${t('staticPage.aisp.listcreditAccTransData')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ</code> {`${t('staticPage.aisp.listAllcreditAccTransData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditTransacationReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successcreditAccTransData')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditTransacationResp} loading="lazy"/>
                  </div>
                  <h3>3.5.5 {`${t('staticPage.aisp.creditRecentAccTransData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET /api​/providers​/integrated​/{aspspId}​/credit-cards-accounts​/accounts/{creditCardAccountId}/transactions-current`}</code>API {`${t('staticPage.aisp.listRecentAccTransData')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CREDIT_CARDS_ACCOUNTS_TRANSACTIONS_READ</code> {`${t('staticPage.aisp.listAllRecentAccTransData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCurrentTransacationReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successRecentAccTransData')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCurrentTransacationResp} loading="lazy"/>
                  </div>
                  <h3>3.5.6 {`${t('staticPage.aisp.creditAccBillsData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET /api​/providers​/integrated​/{aspspId}​/credit-cards-accounts​/accounts/{creditCardAccountId}/bills`}</code>API {`${t('staticPage.aisp.listAccBillsData')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>: {`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CREDIT_CARDS_ACCOUNTS_BILLS_READ</code> {`${t('staticPage.aisp.listAllAccBillsData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditBillsReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successAccBillsData')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditBillsResp} loading="lazy"/>
                  </div>
                  <h3>3.5.7 {`${t('staticPage.aisp.creditAccBillsTransData')}`}</h3>
                  <p>{`${t('staticPage.aisp.tryOut')}`} <code>{String.raw`GET /api​/providers​/integrated​/{aspspId}​/credit-cards-accounts​/accounts/{creditCardAccountId}/bills/{billId}/transactions`}</code>API {`${t('staticPage.aisp.listAccBillsTransData')}`} (<code>aspspId</code>) {`${t('staticPage.aisp.byEndUser')}`} (<code>x-user-id</code>).</p>
                  <b>Note</b>:{`${t('staticPage.aisp.whenExec')}`} <code>{String.raw`POST ​/api​/providers​/integrated​/{aspspId}`}</code>{`${t('staticPage.aisp.required')}`}<code>CREDIT_CARDS_ACCOUNTS_BILLS_TRANSACTIONS_READ</code> {`${t('staticPage.aisp.listAllAccBillsTransData')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditBillsTransReq} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.successAccBillsTransData')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={getCreditBillsTransResp} loading="lazy"/>
                  </div>
                  <p>{`${t('staticPage.aisp.procedure')}`}.</p>
                </div>
              </div>
            </section>
          </div>
        </>
  )
}

export default AispFlow