import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import config from 'components/config';
import { HtmlDocumentFrame } from 'components/business-components';
import {getNewApiBaseUrl} from 'components/common/helpers'

const PaymentsApi = () => {
  const { t } = useTranslation();
  const content = {
    title: t('pages.documentation.paymentsApi'),
    className: 'userManagementImage'
  }
  
  const apiBaseUrl = `${getNewApiBaseUrl(config.apiBase)}/documents`

  return (
    <>
      <Helmet title={content.title}/>
      <div id="main">
        <section class="section section--gradient">
          <div class="inner content">
            <div class="page-content">
                <HtmlDocumentFrame src={`${apiBaseUrl}/pisp-html`} />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PaymentsApi;
