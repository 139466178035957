import React from 'react'

import {Helmet} from 'react-helmet';
import { useTranslation } from 'react-i18next'

import Banner from 'components/banner';
import googleAuth from '../../../../assets/images/google-auth.png'


const GettingStarted = () => {
  const { t } = useTranslation();
  const content ={
    title: t('staticPage.userGuide.developerOnboard'),
    description: t('staticPage.developerOnboard.description'),
    className: 'getStartedImage'
  }


  return (
        <>
          <Helmet title={content.title} />
          {/* <Banner {...content}/> */}
          <div id="main">
            <section class="section section--gradient">
              <div class="inner content">
                <div class="page-content">
                <h3>{`${t('staticPage.userGuide.developerOnboard')}`}</h3>
                  <p>{`${t('staticPage.developerOnboard.description')}`}</p>
                  <h3>{`${t('common.registration')}`}</h3>
                  <p>{`${t('staticPage.developerOnboard.registrationDescription')}`}</p>
                  <h3>{`${t('common.login')}`}</h3>
                  <p>{`${t('staticPage.developerOnboard.loginDescription')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                    <img style={{border: '1px solid'}} src={googleAuth} alt="onboarding-registration"/>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
  )
}

export default GettingStarted