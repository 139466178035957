import React from 'react';

import { useTranslation } from 'react-i18next';
import { Page } from 'components/common';
import './privacyPolicy.scss';


const PrivacyPolicy = () => {
    const { t, i18n } = useTranslation();

    return (
      <Page>
      <div className="inner content policyPage" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
        <div className="page-content">
          <h2 class="center">{t('privacyPolicy.mainHeading')}</h2>
          <p class="center subtitle">{t('privacyPolicy.subTitle')}</p>
          <p>{t('ebanxPrivacyPolicy.lastModified')}</p>
          <p>{t('ebanxPrivacyPolicy.description')}</p>
          <p>{t('ebanxPrivacyPolicy.sideHeading')}</p>
          <div>{t('ebanxPrivacyPolicy.desc1')}</div>
          <div>{t('ebanxPrivacyPolicy.desc2')}</div>
          <div>{t('ebanxPrivacyPolicy.desc3')}</div>
          <div>{t('ebanxPrivacyPolicy.desc4')}</div>
          <div>{t('ebanxPrivacyPolicy.desc5')}</div>
          <div>{t('ebanxPrivacyPolicy.desc6')}</div>
          <div>{t('ebanxPrivacyPolicy.desc7')}</div>
          <div>{t('ebanxPrivacyPolicy.desc8')}</div>
          <div>{t('ebanxPrivacyPolicy.desc9')}</div>
          <div>{t('ebanxPrivacyPolicy.desc10')}</div>
          <div>{t('ebanxPrivacyPolicy.desc11')}</div>
          <div>{t('ebanxPrivacyPolicy.desc12')}</div>

          <h3>{t('ebanxPrivacyPolicy.section1.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section1.description1')}</p>
          <p>{t('ebanxPrivacyPolicy.section1.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section1.description3')}</p>
          <p>{t('ebanxPrivacyPolicy.section1.description4')}</p>
          <p>{t('ebanxPrivacyPolicy.section1.description5')}</p>

          <h3>{t('ebanxPrivacyPolicy.section2.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section2.description1')}</p>

          <ol>
            <li>{t('ebanxPrivacyPolicy.section2.orderedList1')}</li>
            <li>{t('ebanxPrivacyPolicy.section2.orderedList2')}</li>
            <li>{t('ebanxPrivacyPolicy.section2.orderedList3')}</li>
            <li>{t('ebanxPrivacyPolicy.section2.orderedList4')}</li>
            <li>{t('ebanxPrivacyPolicy.section2.orderedList5')}</li>
          </ol>
          <p>{t('ebanxPrivacyPolicy.section2.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section2.description3')}</p>

          <h3>{t('ebanxPrivacyPolicy.section3.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section3.description1')}</p>
          <p>{t('ebanxPrivacyPolicy.section3.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section3.description3')}</p>
          <p>{t('ebanxPrivacyPolicy.section3.description4')}</p>

          <h3>{t('ebanxPrivacyPolicy.section4.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section4.description1')}</p>
          <table>
            <thead>
              <tr>
                <th className="title">{t('ebanxPrivacyPolicy.section4.tableData.section1.head')}</th>
                <th className="title">{t('ebanxPrivacyPolicy.section4.tableData.section2.head')}</th>
                <th className="title">{t('ebanxPrivacyPolicy.section4.tableData.section3.head')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section1.row1')}</td>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section2.row1')}</td>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section3.row1')}</td>
              </tr>
              <tr>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section1.row2')}</td>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section2.row2')}</td>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section3.row2')}</td>
              </tr>
              <tr>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section1.row3')}</td>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section2.row3')}</td>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section3.row3')}</td>
              </tr>
              <tr>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section1.row4')}</td>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section2.row4')}</td>
                <td>{t('ebanxPrivacyPolicy.section4.tableData.section3.row4')}</td>
              </tr>
            </tbody>
          </table>
          <p>{t('ebanxPrivacyPolicy.section4.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section4.description3')}</p>
          <p>{t('ebanxPrivacyPolicy.section4.description4')}</p>
          <p>{t('ebanxPrivacyPolicy.section4.description5')}</p>

          <h3>{t('ebanxPrivacyPolicy.section5.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section5.description1')}</p>
          <p>{t('ebanxPrivacyPolicy.section5.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section5.description3')}</p>
          <p>{t('ebanxPrivacyPolicy.section5.description4')}</p>

          <h3>{t('ebanxPrivacyPolicy.section6.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section6.description1')}</p>
          <p>{t('ebanxPrivacyPolicy.section6.description2')}</p>

          <h3>{t('ebanxPrivacyPolicy.section7.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section7.description1')}</p>
          <p>{t('ebanxPrivacyPolicy.section7.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section7.description3')}</p>

          <h3>{t('ebanxPrivacyPolicy.section8.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section8.description1')}</p>
          <p>{t('ebanxPrivacyPolicy.section8.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section8.description3')}</p>
          <p>{t('ebanxPrivacyPolicy.section8.description4')}</p>


          <h3>{t('ebanxPrivacyPolicy.section9.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section9.description1')}</p>

          <ol>
            <li>{t('ebanxPrivacyPolicy.section9.orderedList1')}</li>
            <li>{t('ebanxPrivacyPolicy.section9.orderedList2')}</li>
            <li>{t('ebanxPrivacyPolicy.section9.orderedList3')}</li>
            <li>{t('ebanxPrivacyPolicy.section9.orderedList4')}</li>
            <li>{t('ebanxPrivacyPolicy.section9.orderedList5')}</li>
            <li>{t('ebanxPrivacyPolicy.section9.orderedList6')}</li>
            <li>{t('ebanxPrivacyPolicy.section9.orderedList7')}</li>
            <li>{t('ebanxPrivacyPolicy.section9.orderedList8')}</li>
          </ol>
          <p>{t('ebanxPrivacyPolicy.section9.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section9.description3')}</p>
          <p>{t('ebanxPrivacyPolicy.section9.description4')}</p>
          
          <h3>{t('ebanxPrivacyPolicy.section10.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section10.description1')}</p>
          <p>{t('ebanxPrivacyPolicy.section10.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section10.description3')}</p>
          <p>
            <span className="title">{t('ebanxPrivacyPolicy.section10.definitionRow.title1')}</span>
            <span>{t('ebanxPrivacyPolicy.section10.definitionRow.description1')}</span>
          </p>
          <p>
            <span className="title">{t('ebanxPrivacyPolicy.section10.definitionRow.title2')}</span>
            <span>{t('ebanxPrivacyPolicy.section10.definitionRow.description2')}</span>
          </p>
          <p>
            <span className="title">{t('ebanxPrivacyPolicy.section10.definitionRow.title3')}</span>
            <span>{t('ebanxPrivacyPolicy.section10.definitionRow.description3')}</span>
          </p>
          <p>
            <span className="title">{t('ebanxPrivacyPolicy.section10.definitionRow.title4')}</span>
            <span>{t('ebanxPrivacyPolicy.section10.definitionRow.description4')}</span>
          </p>
          <p>{t('ebanxPrivacyPolicy.section10.description4')}</p>
          <p>{t('ebanxPrivacyPolicy.section10.description5')}</p>
          <p>{t('ebanxPrivacyPolicy.section10.description6')}</p>
          <p>
            <a href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">{t('ebanxPrivacyPolicy.section10.for')} Internet Explorer™</a>
          </p>
          <p>
            <a href="https://support.apple.com/en-gb/HT201265" target="_blank">{t('ebanxPrivacyPolicy.section10.for')} Safari™</a>
          </p>
          <p>
            <a href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank">{t('ebanxPrivacyPolicy.section10.for')} Chrome™</a>
          </p>
          <p>
            <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US" target="_blank">{t('ebanxPrivacyPolicy.section10.for')} Firefox™</a>
          </p>
          <p>
            <a href="https://help.opera.com/en/latest/web-preferences/#cookies" target="_blank">{t('ebanxPrivacyPolicy.section10.for')} Opera™</a>
          </p>

          <p>{t('ebanxPrivacyPolicy.section10.description7')}</p>
          <p>{t('ebanxPrivacyPolicy.section10.description8')}</p>


          <h3>{t('ebanxPrivacyPolicy.section11.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section11.description1')}</p>
          <p>{t('ebanxPrivacyPolicy.section11.description2')}</p>
          <p>{t('ebanxPrivacyPolicy.section11.description3')}</p>

          <h3>{t('ebanxPrivacyPolicy.section12.title')}</h3>
          <p>{t('ebanxPrivacyPolicy.section12.description1')}</p>

        </div>
        </div>
      </Page>
    )
}

export default PrivacyPolicy;
// export default () => <DynamicMarkdown url={config.termsConditions} defaultContent={DefaultContent}/>;