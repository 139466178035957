import React, {useState} from 'react'

import {Helmet} from 'react-helmet';
import { useTranslation } from 'react-i18next'

import Banner from 'components/banner';
import { Tooltip } from 'components/common';
import clientmanagement from '../../../../assets/images/client-management.png'
import PtClientmanagement from '../../../../assets/images/client-management-pt.png'
import products from '../../../../assets/images/products.png'
import Ptproducts from '../../../../assets/images/products-pt.png'
import aspsp from '../../../../assets/images/aspsp.png'
import Ptaspsp from '../../../../assets/images/aspsp-pt.png'
import clientRegistration from '../../../../assets/images/client-registration.png'
import PtclientRegistration from '../../../../assets/images/client-registration-pt.png'
import dcrCreateForm from '../../../../assets/images/dcr-create-form.png'
import dcrClient from '../../../../assets/images/dcr_client.png'
import PtdcrClient from '../../../../assets/images/dcr_client-pt.png'
import './docs.scss';

const ClientManagement = () => {
  const { t, i18n } = useTranslation();
  const [copyPrivateKey, setCopyPrivateKey] = useState(false);
  const [copyPublicKey, setCopyPublicKey] = useState(false);
  const [copySignPrivateKey, setCopySignPrivateKey] = useState(false);

  const content ={
    title: t('staticPage.userGuide.aspspClientManagement'),
    description: t('staticPage.clientManagement.description')
  }

  const handleCopyPrivateKeyClick = (children) => {
    navigator.clipboard.writeText(children.textContent);

    setCopyPrivateKey(true);
    setTimeout(() => setCopyPrivateKey(false), 1000);
  };

  const handleCopyPublicKeyClick = (children) => {
    navigator.clipboard.writeText(children.textContent);

    setCopyPublicKey(true);
    setTimeout(() => setCopyPublicKey(false), 1000);
  };

  const handleCopySignPrivateKeyClick = (children) => {
    navigator.clipboard.writeText(children.textContent);

    setCopySignPrivateKey(true);
    setTimeout(() => setCopySignPrivateKey(false), 1000);
  };

  return (
        <>
          <Helmet title={content.title} />
          {/* <Banner {...content}/> */}
          <div id="main">
            <section class="section section--gradient">
              <div class="inner content">
                <div class="page-content">
                  <h3>{`${t('staticPage.userGuide.aspspClientManagement')}`}</h3>
                  <p>{`${t('staticPage.clientManagement.description')}`}</p>
                  <p>{`${t('staticPage.clientManagement.users')}`} <code>{`${t('staticPage.clientManagement.admin')}`}</code> {`${t('staticPage.clientManagement.permission')}`}</p>
                  <h3>{`${t('staticPage.clientManagement.addClient')}`}</h3>
                  <ul>
                    <li>{`${t('staticPage.userManagement.addUser.login')}`}</li>
                    <li>{`${t('staticPage.userManagement.addUser.goTo')}`} <code>{`${t('staticPage.userGuide.aspspClientManagement')}`}</code> {`${t('staticPage.userManagement.addUser.tab')}`}
                        <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={i18n.language == 'en' ? clientmanagement: PtClientmanagement} loading="lazy" alt="onboarding-registration"/>
                        </div>
                    </li>
                    <li>{`${t('staticPage.clientManagement.add')}`} <code>{`${t('staticPage.clientManagement.dcr')}`}</code>.</li>
                    <li>{`${t('staticPage.clientManagement.products')}`}:
                        <ol>
                            <li>{`${t('staticPage.clientManagement.aisp')}`}</li>
                            <li>{`${t('staticPage.clientManagement.pisp')}`}</li>
                        </ol>
                        <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={i18n.language == 'en' ? products: Ptproducts} loading="lazy" alt="onboarding-registration"/>
                        </div>
                    </li>
                    <li>
                      {`${t('staticPage.clientManagement.selectAspsp')}`}
                        <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={i18n.language == 'en' ? aspsp: Ptaspsp} loading="lazy" alt="onboarding-registration"/>
                        </div>
                    </li>
                    <li>
                      {`${t('staticPage.clientManagement.payment')}`}
                        <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={i18n.language == 'en' ? clientRegistration: PtclientRegistration} loading="lazy" alt="onboarding-registration"/>
                        </div>
                    </li>
                  </ul>
                  <h3>{`${t('staticPage.clientManagement.createDcr')}`}</h3>
                  <p>{`${t('staticPage.clientManagement.popup')}`} <code>{`${t('staticPage.clientManagement.privateKey')}`}</code>, <code>{`${t('staticPage.clientManagement.cert')}`}</code> {`${t('staticPage.clientManagement.needtoFill')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                      <img style={{border: '1px solid'}} src={dcrCreateForm} loading="lazy" alt="onboarding-registration"/>
                  </div>
                  <p>{`${t('staticPage.clientManagement.pastePrivatekey')}`} <b>{`${t('staticPage.clientManagement.mtlsPrivateKey')}`}</b> , <b>{`${t('staticPage.clientManagement.mtlsPublicKey')}`}</b>, <b>{`${t('staticPage.clientManagement.kid')}`}</b> and <b>{`${t('staticPage.clientManagement.signaturePrivateKey')}`}</b> {`${t('staticPage.clientManagement.field')}`} </p>
                                    
                  <p>{`${t('staticPage.clientManagement.followSteps')}`} <a href="/doc/software-statement" style={{color: '#2228E2'}}>{`${t('staticPage.clientManagement.here')}`}</a></p>
                  <p>{`${t('staticPage.clientManagement.jwt')}`} <code>{`${t('staticPage.clientManagement.ssa')}`}</code> {`${t('staticPage.clientManagement.clickDcr')}`} <code> {`${t('staticPage.clientManagement.runDcr')}`}</code>.</p>
                  <p>{`${t('staticPage.clientManagement.success')}`}</p>
                  <div style={{width: '55%', padding: '20px'}}>
                    <img style={{border: '1px solid'}} src={i18n.language == 'en' ? dcrClient: PtdcrClient} loading="lazy" alt="onboarding-registration"/>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
  )
}

export default ClientManagement;