import React, { useState, useCallback, useMemo } from 'react';
import Banner from 'components/banner';
import { Row, Col } from 'reactstrap';
import { Input, Button } from 'components/common';
import Faq from 'components/business-components/faq';
import RightSideCircleSvg from './right-side-circle.svg';
import LeftSideCircleSvg from './left-side-circle.svg';
import HighlightSvg from './highlight.svg';
import './contact.scss';
import Service from 'service';
// eslint-disable-next-line import/no-named-as-default
import toast from 'react-hot-toast';
import { showError } from 'utils';
import { useTranslation } from 'react-i18next';
import config from 'components/config';

const contactItems = [
  { 
    icon: 'fa fa-map', 
    title: 'pages.contact.officeAddress', 
    description: `${config.companyAddressLine1}, ${config.companyAddressLine2}, ${config.companyAddressCity}, ${config.companyAddressPostcode}`
  },
  { icon: 'fa fa-globe', title: 'pages.contact.website', description: config.companyWebsite, path: config.companyWebsite },
  { icon: 'fa fa-envelope', title: 'pages.contact.email', description: config.companyEmail, path: 'mailto:' + config.companyEmail },
  { icon: 'fa fa-phone', title: 'pages.contact.phone', description: config.companyPhone }
];

const emptyValues = {
  name: '',
  lastname: '',
  email: '',
  message: ''
};

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(emptyValues);
  const { t } = useTranslation();

  const onInputChange = useCallback(({ target }) => {
    const { id, value } = target;
    setValues({ ...values, [id]: value });
  }, [values]);

  const isValid = useMemo(() => values.name && values.lastname && values.email && values.message, [values]);

  const onSubmit = useCallback(async () => {
    setLoading(true);

    try {
      await Service.contact.message(values);
      toast.success(t('pages.contact.messageSentSuccessfully'));
      setValues(emptyValues);
    } catch (err) {
      console.log(err);
      showError(err);
    }

    setLoading(false);
  }, [values]);

  return (
    <div className="contact">
      <div className="bannerWrapper">
        <Banner
          topTitle={t('pages.contact.topTitle')}
          title={t('pages.contact.title')}
          description={t('pages.contact.description')}
          className="banner"
          rightContent={
            <div>
              <figure className="contactForm-highlight-svg">
                <img src={HighlightSvg} />
              </figure>
              <figure className="contactForm-leftSideCircle-svg">
                <img src={LeftSideCircleSvg} />
              </figure>
              <figure className="contactForm-rightSideCircle-svg">
                <img src={RightSideCircleSvg} />
              </figure>
              <div className="formWrapper">
                <div className="form">
                  <Row className="line">
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <Input
                        label={t('pages.contact.firstName')}
                        required
                        value={values.name}
                        name="name"
                        id="name"
                        onChange={onInputChange}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <Input
                        label={t('pages.contact.lastName')}
                        required
                        value={values.lastname}
                        name="lastname"
                        id="lastname"
                        onChange={onInputChange}
                      />
                    </Col>
                  </Row>
                  <Input
                    label={t('pages.contact.email')}
                    required
                    wrapperClass="line"
                    value={values.email}
                    name="email"
                    id="email"
                    onChange={onInputChange}
                  />
                  <Input
                    label={t('pages.contact.message')}
                    required
                    wrapperClass="line"
                    type="textarea"
                    value={values.message}
                    name="message"
                    id="message"
                    onChange={onInputChange}
                  />

                  <Button
                    className="submit"
                    disabled={loading || !isValid}
                    onClick={onSubmit}
                  >
                    {t('pages.contact.submitNow')}
                  </Button>
                </div>
              </div>
            </div>
          }
        />
      </div>
      <div className="contact-whole-wrapper">
        <div className="contactItems">
          <Row>
            {contactItems?.map((item) => (
              <Col xs={12} sm={6} md={3} lg={3} key={item.title || item.path}>
                <div key={item.title} className="item">
                  <i className={item.icon} />
                  <div className="title">{t(item.title)}</div>
                  {item.path ? (
                    <a className="description" href={item.path}>
                      {item.description}
                    </a>
                  ) : (
                    <div className="description">{item.description}</div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className="faqWrapper">
        <div className="faqInnerContainer">
          <Faq topTitle={t('components.faq.questionsHere')} />
        </div>
      </div>
    </div>
  )
};

export default Contact;