import React from 'react';

import { useTranslation } from 'react-i18next';
import { Page } from 'components/common';
import './termsAndConditions.scss';


const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();

    return (
      <Page pageTitle={t('termsAndConditions.tabTitle')}>
        <div className="inner content termsPage" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
        <div className="page-content">
          <h2 class="center">{t('termsAndConditions.mainHeading')}</h2>
          <p class="center subtitle">{t('termsAndConditions.subTitle')}</p>
          <p>{t('termsAndConditions.lastModified')}</p>
          <h3>{t('termsAndConditions.section1.title')}</h3>
          <p>{t('termsAndConditions.section1.description1')}</p>
          <p>{t('termsAndConditions.section1.description2')}</p>
          <p>{t('termsAndConditions.section1.description3')}</p>
          <h3>{t('termsAndConditions.section2.title')}</h3>
          <ol>
            <li>{t('termsAndConditions.section2.orderedList1')}</li>
            <li>{t('termsAndConditions.section2.orderedList2')}</li>
            <ul>
              <li>{t('termsAndConditions.section2.unorderedList1')}</li>
              <li>{t('termsAndConditions.section2.unorderedList2')}</li>
            </ul>
            <li>{t('termsAndConditions.section2.orderedList3')}</li>
          </ol>
          <h3>{t('termsAndConditions.section3.title')}</h3>
          <ol start="4">
            <li>{t('termsAndConditions.section3.orderedList1')}</li>
            <ul>
              <li>{t('termsAndConditions.section3.unorderedList1')}</li>
              <li>{t('termsAndConditions.section3.unorderedList2')}</li>
              <li>{t('termsAndConditions.section3.unorderedList3')}</li>
            </ul>
          </ol>
          <h3>{t('termsAndConditions.section4.title')}</h3>
          <ol start="5">
            <li>{t('termsAndConditions.section4.orderedList1')}</li>
            <li>{t('termsAndConditions.section4.orderedList2')}</li>
            <li>{t('termsAndConditions.section4.orderedList3')}</li>
            <li>{t('termsAndConditions.section4.orderedList4')}</li>
          </ol>
          <h3>{t('termsAndConditions.section5.title')}</h3>
          <ol start="9">
            <li>{t('termsAndConditions.section5.orderedList1')}</li>
          </ol>
          <p>{t('termsAndConditions.section5.description')}</p>
        </div>
        </div>
      </Page>
    )
}

export default TermsAndConditions;