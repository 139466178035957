import React from 'react'

import {Helmet} from 'react-helmet';
import { useTranslation } from 'react-i18next'

import Banner from 'components/banner';
import settings from '../../../../assets/images/settings.png'
import certificates from '../../../../assets/images/certificates.png'
import certAdd from '../../../../assets/images/cert-add.png'
import certAddedPostman from '../../../../assets/images/cert-added-postman.png'
import environmentSelect from '../../../../assets/images/environment-select.png'
import executeToken from '../../../../assets/images/execute-token.png'
import sslCertDisable from '../../../../assets/images/ssl-cert-disable.png'
import softwareStatement from '../../../../assets/images/software-statement.png'

const SoftwareStatement = () => {
  const { t } = useTranslation();
  const content ={
    title: t('staticPage.softwareStatement.title'),
    description: t('staticPage.softwareStatement.description'),
    className: 'userManagementImage'
  }


  return (
        <>
          <Helmet title={content.title} />
          {/* <Banner {...content}/> */}
          <div id="main">
            <section class="section section--gradient">
              <div class="inner content">
                <div class="page-content">
                <h3>{`${t('staticPage.softwareStatement.title')}`}</h3>
                  <p>{`${t('staticPage.softwareStatement.description')}`}</p>
                  <h3>{`${t('staticPage.softwareStatement.addCert')}`}</h3>
                  <ul>
                    <li>{`${t('staticPage.softwareStatement.onClick')}`} <code>{`${t('staticPage.softwareStatement.setting')}`}</code> {`${t('staticPage.softwareStatement.select')}`} <code>Settings</code> option.</li>
                    <div style={{width: '55%', padding: '20px'}}>
                    <img style={{border: '1px solid'}} src={settings} loading="lazy" alt="onboarding-registration"/>
                    </div>
                    <li>{`${t('staticPage.softwareStatement.selectCert')}`} <code>Certificates</code>{`${t('staticPage.softwareStatement.click')}`} <code>Add Certificate</code>.</li>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={certificates} loading="lazy" alt="onboarding-registration"/>
                    </div>
                    <li>{`${t('staticPage.softwareStatement.host')}`} <code>cert</code> and <code>key</code> files.</li>
                    <p>&nbsp;</p>
                    <b>Host</b> : <code>*.sandbox.directory.openbankingbrasil.org.br</code>
                    <p>&nbsp;</p>
                    <li>{`${t('staticPage.softwareStatement.onClick')}`}<code>Add</code> {`${t('staticPage.softwareStatement.button')}`}.</li>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={certAdd} loading="lazy" alt="onboarding-registration"/>
                    </div>
                    <li>{`${t('staticPage.softwareStatement.success')}`} <code>Certificates</code> tab.</li>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={certAddedPostman} loading="lazy" alt="onboarding-registration"/>
                    </div>
                </ul>
                <h3>{`${t('staticPage.softwareStatement.postman')}`}</h3>
                <ul>
                  <li>{`${t('staticPage.softwareStatement.importCollection')}`}
                    <div style={{display: 'flex', marginLeft: '40px', marginTop: '10px', marginBottom: '20px'}}>
                        <div style={{width: '450px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', marginRight: '20px', padding: '10px 0'}}>
                            <span style={{display: 'flex'}}>
                                <a href="/46aa9389a99aa3021b68f4e208d79666/SSA.postman_collection.json" style={{color: '#2228E2'}} download>SSA.postman_collection.json</a>
                            </span>
                        </div>
                        {/* <div style={{width: '450px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', padding: '10px 0'}}>
                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <a href="/1e371a7d27788b0b7924337cc501b602/SSA.postman_environment.json" style={{color: '#2228E2'}} download>SSA.postman_environment.json</a>
                            </span>
                        </div> */}
                    </div>
                  </li>
                  <li>{`${t('staticPage.softwareStatement.openCollection')}`} <code>OBAF</code>.
                  <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={environmentSelect}/>
                  </div>
                  </li>
                  <li>{`${t('staticPage.softwareStatement.provide')}`} <code>directory_token_endpoint</code> and <code>client_id</code> {`${t('staticPage.softwareStatement.execute')}`} <code>Directory Token</code> {`${t('staticPage.softwareStatement.dcrFolder')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                      <img style={{border: '1px solid'}} src={executeToken}/>
                    </div>
                  </li>
                  <b>Note</b>: {`${t('staticPage.softwareStatement.sslCert')}`}
                  <div style={{width: '55%', padding: '20px'}}>
                      <img style={{border: '1px solid'}} src={sslCertDisable}/>
                  </div>
                  <li> {`${t('staticPage.softwareStatement.provideSpecific')}`} <code>orgId</code> and <code>ssaId</code> {`${t('staticPage.softwareStatement.execute')}`} <code>{`${t('staticPage.clientManagement.ssa')}`}</code> {`${t('staticPage.softwareStatement.dcrFolder')}`} {`${t('staticPage.softwareStatement.jwt')}`} </li>
                  <div style={{width: '55%', padding: '20px'}}>
                      <img style={{border: '1px solid'}} src={softwareStatement}/>
                  </div>
                </ul>
                </div>
              </div>
            </section>
          </div>
        </>
  )
}

export default SoftwareStatement