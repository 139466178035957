import React from 'react'

import {Helmet} from 'react-helmet';
import { useTranslation } from 'react-i18next'

import Banner from 'components/banner';
import envSelect from '../../../../assets/images/pisp-env-select.png'
import tokenReq from '../../../../assets/images/pisp-token-req.png'
import availableAspsp from '../../../../assets/images/available-aspsp-postman.png'
import authUri from '../../../../assets/images/auth-uri-postman.png'
import listPayments from '../../../../assets/images/list-payments.png'
import paymentDetail from '../../../../assets/images/payment-detail.png'

const PostmanFlow = () => {
  const { t } = useTranslation();
  const content ={
    title: t('staticPage.postmanFlow.title'),
    description: t('staticPage.postmanFlow.description'),
    className: 'userManagementImage'
  }


  return (
        <>
          <Helmet title={content.title} />
          {/* <Banner {...content}/> */}
          <div id="main">
            <section class="section section--gradient">
              <div class="inner content">
                <div class="page-content">
                    <h3>{`${t('staticPage.postmanFlow.title')}`}</h3>
                    <p>{`${t('staticPage.postmanFlow.description')}`}</p>
                    <h3>{`${t('staticPage.postmanFlow.supportedFlows')}`}:</h3>
                    <ul>
                      <li><a href="#aisp" style={{color: '#2228E2', fontWeight: 'bold'}}>{`${t('pages.documentation.aispFlow')}`}</a></li>
                      <li><a href="#pisp" style={{color: '#2228E2', fontWeight: 'bold'}}>{`${t('pages.documentation.pispFlow')}`}</a></li>
                    </ul>
                    <span id="pisp"></span>
                    <h3 style={{border: '1px solid rgba(0, 0, 0, 0.12)', padding: '10px', borderRadius: '10px', backgroundColor: '#EEF0EF'}}>{`${t('staticPage.postmanFlow.pispFlow')}`}</h3>
                    <ul>
                      <li>{`${t('staticPage.postmanFlow.import')}`}
                        <div style={{display: 'flex', marginLeft: '40px', marginTop: '10px', marginBottom: '20px'}}>
                            <div style={{width: '450px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', marginRight: '20px', padding: '10px 0'}}>
                                <span style={{display: 'flex'}}>
                                    <a href="/d62ef64ee50415f52c59a1e29948004a/PISP-APIs.postman_collection.json" download style={{color: '#2228E2'}}>PISP-Flow.postman_collection.json</a>
                                </span>
                            </div>
                            <div style={{width: '450px', borderTop: '1px solid rgba(0, 0, 0, 0.12)', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', padding: '10px 0'}}>
                                <span style={{display: 'flex', alignItems: 'center'}}>
                                    <a href="/f1675227ddd59d0ddf91c3258fa16988/PISP-APIs.postman_environment.json" download style={{color: '#2228E2'}}>PISP-Flow.postman_environment.json</a>
                                </span>
                            </div>
                        </div>
                      </li>
                      <li>{`${t('staticPage.postmanFlow.openEnv')}`} <code>CLIENT_ID</code>, <code>CLIENT_SECRET</code>, <code>FINTECH_ID</code>, <code>X_USER_ID</code> and <code>ASPSP_ID</code> {`${t('staticPage.postmanFlow.save')}`}</li>
                      <li>{`${t('staticPage.postmanFlow.openCollection')}`} <code>OBAF</code> {`${t('staticPage.postmanFlow.selectEnv')}`} <code>OBAF-PISP-SBX</code>.</li>
                      <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={envSelect}/>
                      </div>
                      <li>{`${t('staticPage.postmanFlow.executeToken')}`}</li>
                      <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={tokenReq}/>
                      </div>
                      <li>{`${t('staticPage.postmanFlow.execute')}`} <code>List of Available ASPSPs</code>{`${t('staticPage.postmanFlow.response')}`} </li>
                      <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={availableAspsp}/>
                      </div>
                      <li>{`${t('staticPage.postmanFlow.execute')}`} <code>Make Domestic Payment</code> {`${t('staticPage.postmanFlow.particularEndUser')}`}. {`${t('staticPage.postmanFlow.onSuccess')}`} <code>authUri</code> {`${t('staticPage.postmanFlow.bankAuthUri')}`}.</li>
                      <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={authUri}/>
                      </div>
                      <li>{`${t('staticPage.postmanFlow.bankAuthPage')}`}</li>
                      <li>{`${t('staticPage.postmanFlow.creds')}`}</li>
                      <li>{`${t('staticPage.postmanFlow.paymentConfirm')}`}</li>
                      <li>{`${t('staticPage.postmanFlow.execute')}`} <code>List of Domestic Payments</code> {`${t('staticPage.postmanFlow.listPayments')}`}</li>
                      <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={listPayments}/>
                      </div>
                      <li>{`${t('staticPage.postmanFlow.execute')}`} <code>Payment Details</code>{`${t('staticPage.postmanFlow.specificPayment')}`} </li>
                      <div style={{width: '55%', padding: '20px'}}>
                          <img style={{border: '1px solid'}} src={paymentDetail}/>
                      </div>
                    </ul>
                </div>
              </div>
            </section>
          </div>
        </>
  )
}

export default PostmanFlow