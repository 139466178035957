import React from 'react';
import Contact from 'components/content/public-pages/contact';
import Privacy from 'components/content/public-pages/privacy';
import PrivacyPolicy from 'components/content/public-pages/ebanx/privacy';
import TermsConditions from 'components/content/public-pages/terms-conditions';
import TermsAndConditions from 'components/content/public-pages/ebanx/terms-conditions';
import Documentation from 'components/content/public-pages/docs';
import Layout from 'components/layout';
import { Router } from '@reach/router';

const routes = [
  { path: '/contact', component: Contact },
  { path: '/privacy-policy', component: Privacy },
  { path: '/privacyPolicy', component: PrivacyPolicy },
  { path: '/terms-and-conditions-of-use', component: TermsConditions },
  { path: '/terms-and-conditions', component: TermsAndConditions },
  { path: '/doc/developer-onboarding', component: Documentation},
  { path: '/doc/user-management', component: Documentation},
  { path: '/doc/client-management', component: Documentation },
  { path: '/doc/software-statement', component: Documentation},
  { path: '/doc/aisp-flow', component: Documentation},
  { path: '/doc/pisp-flow', component: Documentation},
  { path: '/doc/postman-flow', component: Documentation},
  { path: '/doc/user-guide', component: Documentation},
  { path: '/doc/accounts-api', component: Documentation},
  { path: '/doc/payments-api', component: Documentation}
];

const SimpleFrag = ({ children }) => <div style={{backgroundColor: 'white'}}>{children}</div>;

const PublicTemplate = (props) => {
  return (
    <Layout>
      <Router component={SimpleFrag}>
        {routes.map((route) => <route.component {...props} key={route.path} path={route.path} />)}
      </Router>
    </Layout>
  );
};

export default PublicTemplate;