import React from 'react'

import {Helmet} from 'react-helmet';
import { useTranslation } from 'react-i18next'

import Banner from 'components/banner';
import apiProducts from '../../../../assets/images/pisp/api-products.png'
import PtapiProducts from '../../../../assets/images/pisp/api-products-pt.png'
import testApp from '../../../../assets/images/pisp/test-app.png'
import PttestApp from '../../../../assets/images/pisp/test-app-pt.png'
import swaggerPisp from '../../../../assets/images/pisp/swagger-pisp.png'
import PtswaggerPisp from '../../../../assets/images/pisp/swagger-pisp-pt.png'
import tppAuth from '../../../../assets/images/pisp/tpp-authentication.png'
import availableAspsp from '../../../../assets/images/pisp/available-aspsp.png'
import authUri from '../../../../assets/images/pisp/auth-uri.png'
import psuAuth from '../../../../assets/images/pisp/psu-auth.png'
import paymentConfirm from '../../../../assets/images/pisp/payment-confirmation.png'
import paymentListReq from '../../../../assets/images/pisp/payments-list-request.png'
import paymentList from '../../../../assets/images/pisp/payment-list.png'


const PispFlow = () => {
  const { t, i18n } = useTranslation();
  const content ={
    title: t('pages.documentation.pispFlow'),
    description: t('staticPage.pisp.description'),
    className: 'userManagementImage'
  }


  return (
        <>
          <Helmet title={content.title} />
          {/* <Banner {...content}/> */}
          <div id="main">
            <section class="section section--gradient">
              <div class="inner content">
                <div class="page-content">
                    <h3>{`${t('pages.documentation.pispFlow')}`}</h3>
                    <p>{`${t('staticPage.pisp.product')}`} <code>PIS</code>.</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={i18n.language == 'en' ? apiProducts : PtapiProducts} loading="lazy" alt="onboarding-registration"/>
                    </div>
                    <p>{`${t('staticPage.pisp.followSteps')}`} :</p>
                    <h3>1. {`${t('staticPage.pisp.selectApp')}`}</h3>
                    <p>{`${t('staticPage.pisp.onClick')}`} <code>Test</code> {`${t('staticPage.pisp.test')}`}</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={i18n.language == 'en' ? testApp : PttestApp} loading="lazy" alt="onboarding-registration"/>
                    </div>
                    <h3>2. {`${t('staticPage.pisp.devAuth')}`}</h3>
                    <p> {`${t('staticPage.pisp.authorize')}`}</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={i18n.language == 'en' ? swaggerPisp : PtswaggerPisp} loading="lazy" alt="swagger-pisp"/>
                    </div>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={tppAuth} loading="lazy" alt="tpp-auth"/>
                    </div>
                    <h3>3. {`${t('staticPage.pisp.availableAspsp')}`}</h3>
                    <p>{`${t('staticPage.pisp.authHeader')}`} {`${t('staticPage.pisp.tryItout')}`} <code>/api/providers/payments/available</code> API.{`${t('staticPage.pisp.available')}`} <code>{`${t('staticPage.pisp.bank')}`}</code> {`${t('staticPage.pisp.fintech')}`}. {`${t('staticPage.pisp.id')}`} <code>aspspId</code>.</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={availableAspsp} loading="lazy"/>
                    </div>
                    <h3>4. {`${t('staticPage.pisp.domesticPaymentCall')}`}</h3>
                    <p>{`${t('staticPage.pisp.tryItout')}`} <code>{String.raw`/api/providers/payments/{aspspId}/domestic`}</code> API. {`${t('staticPage.pisp.paymentRequest')}`} (<code>x-user-id</code>) {`${t('staticPage.pisp.specificBank')}`} (<code>aspspId</code>). {`${t('staticPage.pisp.response')}`} </p>
                    <p>{`${t('staticPage.pisp.onSuccess')}`}<code>authUri</code> {`${t('staticPage.pisp.field')}`} <code>ASPSP/bank Authentication URI</code>. {`${t('staticPage.pisp.authPage')}`}</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={authUri} loading="lazy"/>
                    </div>
                    <p>{`${t('staticPage.pisp.enterCred')}`}</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={psuAuth} loading="lazy"/>
                    </div>
                    <p>{`${t('staticPage.pisp.redirect')}`}</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={paymentConfirm} loading="lazy"/>
                    </div>
                    <h3>5. {`${t('staticPage.pisp.listDomesticPayments')}`}</h3>
                    <p> {`${t('staticPage.pisp.nowTryOut')}`}<code>/api/providers/payments/domestic</code>, {`${t('staticPage.pisp.listPayments')}`} (<code>x-user-id</code>)</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={paymentListReq} loading="lazy"/>
                    </div>
                    <p>{`${t('staticPage.pisp.successReq')}`}</p>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={paymentList} loading="lazy"/>
                    </div>
                </div>
              </div>
            </section>
          </div>
        </>
  )
}

export default PispFlow