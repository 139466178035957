/* eslint-disable no-nested-ternary */
import React from 'react'

import { useTranslation } from 'react-i18next'

import { LeftNav } from 'components/business-components';
import UserGuide from './user-guide';
import GettingStarted from './developer-onboarding';
import UserManagement from './user-management';
import ClientManagement from './client-management';
import SoftwareStatement from './software-statement';
import AispFlow from './aisp-flow';
import PispFlow from './pisp-flow';
import PostmanFlow from './postman-flow';
import AccountsApi from './accounts-api';
import PaymentsApi from './payments-api';

const Documentation = () => {
  const { t } = useTranslation();

  return (
      <div className="docs">
        <div className="left">
          <LeftNav
            navData={[
              {
                label: t('common.home'),
                icon: 'fa fa-home',
                url: '/doc/user-guide'
              },
              {
                label: t('pages.documentation.products'),
                icon: 'fa fa-list-alt',
                subItems: [
                  {
                    label: t('pages.documentation.accountsApi'),
                    url: '/doc/accounts-api'
                  },
                  {
                    label: t('pages.documentation.paymentsApi'),
                    url: '/doc/payments-api'
                  }
                ]
              },
              {
                label: t('pages.documentation.userGuides'),
                icon: 'fa fa-list-alt',
                subItems: [
                  {
                    label: t('pages.documentation.gettingStarted'),
                    url: '/doc/developer-onboarding'
                  },
                  {
                    label: t('pages.documentation.userManagement'),
                    url: '/doc/user-management'
                  },
                  {
                    label: t('pages.documentation.aspspClientManagement'),
                    url: '/doc/client-management'
                  },
                  {
                    label: t('pages.documentation.softwareStatement'),
                    url: '/doc/software-statement',
                    protected: true
                  },
                  {
                    label: t('pages.documentation.aispFlow'),
                    url: '/doc/aisp-flow'
                  },
                  {
                    label: t('pages.documentation.pispFlow'),
                    url: '/doc/pisp-flow'
                  },
                  {
                    label: t('pages.documentation.testingGuides'),
                    url: '/doc/postman-flow'
                  }
                ]
              }
            ]}
          />
        </div>
        <div className="right">
            {window.location.pathname == '/doc/user-guide' ? <UserGuide/>
            : window.location.pathname == '/doc/developer-onboarding' ? <GettingStarted/>
            : window.location.pathname == '/doc/user-management' ? <UserManagement/>
            : window.location.pathname == '/doc/client-management' ? <ClientManagement/>
            : window.location.pathname == '/doc/software-statement' ? <SoftwareStatement/>
            : window.location.pathname == '/doc/aisp-flow' ? <AispFlow/>
            : window.location.pathname == '/doc/pisp-flow' ? <PispFlow/>
            : window.location.pathname == '/doc/postman-flow' ? <PostmanFlow/>
            : window.location.pathname == '/doc/accounts-api'? <AccountsApi/>
            : window.location.pathname == '/doc/payments-api'? <PaymentsApi/> : null}
        </div>
      </div>
  )
}

export default Documentation