import React from 'react'

import {Helmet} from 'react-helmet';
import { useTranslation } from 'react-i18next'

import Banner from 'components/banner';
import usermanagement from '../../../../assets/images/user-management.png'
import Ptusermanagement from '../../../../assets/images/user-management-pt.png'
import addUser from '../../../../assets/images/add-user.png'
import PtAdduser from '../../../../assets/images/add-user-pt.png'
import googleAuth from '../../../../assets/images/google-auth.png'
import resetPassword from '../../../../assets/images/reset-password.png'


const UserManagement = () => {
  const { t, i18n } = useTranslation();
  const content ={
    title: t('staticPage.userGuide.userManagement'),
    description: t('staticPage.userManagement.description'),
    className: 'userManagementImage'
  }

  return (
        <>
          <Helmet title={content.title} />
          {/* <Banner {...content}/> */}
          <div id="main">
            <section class="section section--gradient">
              <div class="inner content">
                <div class="page-content">
                  <h3>{`${t('staticPage.userGuide.userManagement')}`}</h3>
                  <p>{`${t('staticPage.userManagement.description')}`}</p>
                  <p>{`${t('staticPage.userManagement.roles')}`}:</p>
                  <p>{`${t('staticPage.userManagement.roleDescription')}`}:</p>
                  <ul>
                    <li><strong>{`${t('staticPage.userManagement.devRole')}`}</strong> - {`${t('staticPage.userManagement.devRoleDesc')}`}.</li>
                    <li><strong>{`${t('staticPage.userManagement.adminRole')}`}</strong> - {`${t('staticPage.userManagement.adminRoleDesc')}`}.</li>
                  </ul>
                  <h3>{`${t('staticPage.userManagement.addUser.title')}`}</h3>
                  <ul>
                    <li>{`${t('staticPage.userManagement.addUser.login')}`}</li>
                    <li>
                    {`${t('staticPage.userManagement.addUser.goTo')}`} <code>{`${t('staticPage.userGuide.userManagement')}`}</code>{`${t('staticPage.userManagement.addUser.tab')}`}
                        <div style={{width: '55%', padding: '20px'}}>
                            <img style={{border: '1px solid'}} src={i18n.language == 'en' ? usermanagement : Ptusermanagement} loading="lazy" alt="onboarding-registration"/>
                        </div>
                    </li>
                    <li>{`${t('staticPage.userManagement.addUser.add')}`} <code>{`${t('staticPage.userManagement.addUser.title')}`}</code> {`${t('staticPage.userManagement.addUser.rightButton')}`}</li>
                    <li>{`${t('staticPage.userManagement.addUser.addUserScreen')}`}
                        <ul>
                            <li>{`${t('common.name')}`}</li>
                            <li>{`${t('staticPage.userManagement.addUser.email')}`}</li>
                            <li>{`${t('staticPage.userManagement.addUser.userPermissions')}`}</li>
                            <li>{`${t('staticPage.userManagement.addUser.password')}`}</li>
                            <li>{`${t('staticPage.userManagement.addUser.status')}`}</li>
                        </ul>
                        <div style={{width: '55%', padding: '20px'}}>
                            <img style={{border: '1px solid'}} src={i18n.language == 'en' ? addUser : PtAdduser} alt="onboarding-registration"/>
                        </div>
                    </li>
                    <b>Note</b>: {`${t('staticPage.userManagement.addUser.note')}`}
                    <p>&nbsp;</p>
                    <li>{`${t('staticPage.userManagement.addUser.onclick')}`} <code>{`${t('common.save')}`}</code> {`${t('staticPage.userManagement.addUser.userCreated')}`}</li>
                    <li>{`${t('staticPage.userManagement.addUser.googleAuth')}`}</li>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={googleAuth} alt="onboarding-registration"/>
                    </div>
                    <li>{`${t('staticPage.userManagement.addUser.resetPassword')}`}</li>
                    <div style={{width: '55%', padding: '20px'}}>
                        <img style={{border: '1px solid'}} src={resetPassword} alt="onboarding-registration"/>
                    </div>
                    <li>{`${t('staticPage.userManagement.addUser.verifyEmail')}`}</li>
                </ul>
                </div>
              </div>
            </section>
          </div>
        </>
  )
}

export default UserManagement