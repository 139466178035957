import React from 'react';

import { useTranslation } from 'react-i18next';
import { Page } from 'components/common';
import './termsAndConditions.scss';


const TermsAndConditions = () => {
  const { t, i18n } = useTranslation();

    return (
      <Page>
        <div className="inner content termsPage" dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
        <div className="page-content">
          <h2 class="center">{t('termsConditions.mainHeading')}</h2>
          <p class="center subtitle">{t('termsConditions.subTitle')}</p>
          <p>{t('termsConditions.lastModified')}</p>
          <h3>{t('termsConditions.section1.title')}</h3>
          <p>{t('termsConditions.section1.description1')}</p>
          <p>{t('termsConditions.section1.description2')}</p>
          <p>{t('termsConditions.section1.description3')}</p>
          <p>{t('termsConditions.section1.description4')}</p>
          <p>{t('termsConditions.section1.description5')}</p>
          <p>{t('termsConditions.section1.description6')}</p>
          <p>{t('termsConditions.section1.description7')}</p>
          <p>{t('termsConditions.section1.description8')}</p>
          <p>{t('termsConditions.section1.description9')}</p>
          <p>{t('termsConditions.section1.description10')}</p>
          <h3>{t('termsConditions.section2.title')}</h3>
          <ol>
            <li>{t('termsConditions.section2.orderedList1')}</li>
            <li>{t('termsConditions.section2.orderedList2')}</li>
            <li>{t('termsConditions.section2.orderedList3')}</li>
            <li>{t('termsConditions.section2.orderedList4')}</li>
            <li>{t('termsConditions.section2.orderedList5')}</li>
            <li>{t('termsConditions.section2.orderedList6')}</li>
            <li>{t('termsConditions.section2.orderedList7')}</li>
            <li>{t('termsConditions.section2.orderedList8')}</li>
          </ol>
          <h3>{t('termsConditions.section3.title')}</h3>
          <ol start="9">
            <li>{t('termsConditions.section3.orderedList1')}</li>
            <li>{t('termsConditions.section3.orderedList2')}</li>
            <li>{t('termsConditions.section3.orderedList3')}</li>
            <li>{t('termsConditions.section3.orderedList4')}</li>
            <li>{t('termsConditions.section3.orderedList5')}</li>
            <li>{t('termsConditions.section3.orderedList6')}</li>
          </ol>
          <h3>{t('termsConditions.section4.title')}</h3>
          <ol start="15">
            <li>{t('termsConditions.section4.orderedList1')}</li>
            <li>{t('termsConditions.section4.orderedList2')}</li>
            <li>{t('termsConditions.section4.orderedList3')}</li>
            <li>{t('termsConditions.section4.orderedList4')}</li>
            <ul>
              <li>{t('termsConditions.section4.unorderedList1')}</li>
              <li>{t('termsConditions.section4.unorderedList2')}</li>
            </ul>
            <li>{t('termsConditions.section4.orderedList5')}</li>
          </ol>
          <h3>{t('termsConditions.section5.title')}</h3>
          <ol start="20">
            <li>{t('termsConditions.section5.orderedList1')}</li>
          </ol>
          <h3>{t('termsConditions.section6.title')}</h3>
          <ol start="21">
            <li>{t('termsConditions.section6.orderedList1')}</li>
            <li>{t('termsConditions.section6.orderedList2')}</li>
            <li>{t('termsConditions.section6.orderedList3')}</li>
            <ul>
              <li>{t('termsConditions.section6.unorderedList1')}</li>
              <li>{t('termsConditions.section6.unorderedList2')}</li>
              <li>{t('termsConditions.section6.unorderedList3')}</li>
            </ul>
          </ol>
          <h3>{t('termsConditions.section7.title')}</h3>
          <ol start="24">
            <li>{t('termsConditions.section7.orderedList1')}</li>
          </ol>
          <h3>{t('termsConditions.section8.title')}</h3>
          <ol start="25">
            <li>{t('termsConditions.section8.orderedList1')}</li>
          </ol>
          <h3>{t('termsConditions.section9.title')}</h3>
          <ol start="26">
            <li>{t('termsConditions.section9.orderedList1')}</li>
            <li>{t('termsConditions.section9.orderedList2')}</li>
            <li>{t('termsConditions.section9.orderedList3')}</li>
            <li>{t('termsConditions.section9.orderedList4')}</li>
          </ol>
          <h3>{t('termsConditions.section10.title')}</h3>
          <ol start="30">
            <li>{t('termsConditions.section10.orderedList1')}</li>
            <li>{t('termsConditions.section10.orderedList2')}</li>
            <li>{t('termsConditions.section10.orderedList3')}</li>
            <li>{t('termsConditions.section10.orderedList4')}</li>
            <li>{t('termsConditions.section10.orderedList5')}</li>
            <ul>
              <li>{t('termsConditions.section10.unorderedList1')}</li>
              <li>{t('termsConditions.section10.unorderedList2')}</li>
              <li>{t('termsConditions.section10.unorderedList3')}</li>
              <li>{t('termsConditions.section10.unorderedList4')}</li>
              <li>{t('termsConditions.section10.unorderedList5')}</li>
              <li>{t('termsConditions.section10.unorderedList6')}</li>
              <li>{t('termsConditions.section10.unorderedList7')}</li>
              <li>{t('termsConditions.section10.unorderedList8')}</li>
            </ul>
            <li>{t('termsConditions.section10.orderedList6')}</li>
          </ol>
          <h3>{t('termsConditions.section11.title')}</h3>
          <ol start="36">
            <li>{t('termsConditions.section11.orderedList1')}</li>
            <li>{t('termsConditions.section11.orderedList2')}</li>
            <li>{t('termsConditions.section11.orderedList3')}</li>
          </ol>
          <h3>{t('termsConditions.section12.title')}</h3>
          <ol start="39">
            <li>{t('termsConditions.section12.orderedList1')}</li>
          </ol>
          <h3>{t('termsConditions.section13.title')}</h3>
          <ol start="40">
            <li>{t('termsConditions.section13.orderedList1')}</li>
            <li>{t('termsConditions.section13.orderedList2')}</li>
          </ol>
          <h3>{t('termsConditions.section14.title')}</h3>
          <ol start="42">
            <li>{t('termsConditions.section14.orderedList1')}</li>
          </ol>
          <h3>{t('termsConditions.section15.title')}</h3>
          <ol start="43">
            <li>{t('termsConditions.section15.orderedList1')}</li>
          </ol>
          <h3>{t('termsConditions.section16.title')}</h3>
          <ol start="44">
            <li>{t('termsConditions.section16.orderedList1')}</li>
            <li>{t('termsConditions.section16.orderedList2')}</li>
          </ol>
          <p>{t('termsConditions.section16.description1')}</p>
            <p>{t('termsConditions.section16.description2')}</p>
        </div>
        </div>
      </Page>
    )
}

export default TermsAndConditions;